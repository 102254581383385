<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('withdraw2[0]')"
            left-arrow @click-right="onClickRight"
            @click-left="$router.go(-1)"
    ><van-icon name="./skin/task01.png" slot="right" />
    </van-nav-bar>
    <div class="titlePanel">
      <div style="font-size: 25px;color:var(--font_color);font-weight: bold"> {{ Number(UserInfo.balance2).toFixed(2) }} </div>
      <div style="color: var(--font_subtitle)"> {{ $t("wallet.default[9]") }}</div>
      <div style="color: var(--font_subtitle)">{{InitData.usdtinfo.sign}}</div>
    </div>
    <div class="box">
      <div>
        <van-field
            v-model="BankInfo.name"
            :label="$t('bindAccount.fields[0]')"
            :placeholder="$t('bindAccount.placeholder[0]')"
        />
        <van-field
            v-model="BankInfo.phone"
            :label="$t('bindAccount.fields[1]')"
            :placeholder="$t('bindAccount.placeholder[1]')"
        />
        <van-field
            v-model="BankInfo.card_no"
            :label="$t('bindAccount.fields[2]')"
            :placeholder="$t('bindAccount.placeholder[2]')"
        />
        <van-field
            v-model="BankInfo.bank_name"
            :label="$t('bindAccount.fields[3]')"
            :placeholder="$t('bindAccount.placeholder[3]')"
        />
        <van-field v-model="BankInfo.money"
                   :label="$t('wallet.label[1]')"
                   :placeholder="$t('wallet.placeholder[1]')"
        />
      </div>
    </div>
        <div class="centerPanel" style="text-align: center;margin-top: 30px">
          <van-button @click="submitDraw">{{$t('recharge.default[4]')}}</van-button>
        </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import axios from "axios";
import { Toast } from "vant";
import config from "@/config";

export default {
  name: "mixRecharge",
  components: {},
  props: ["walletType"],
  data() {
    return {
      showPicker: false,
      columns:[],
      amount:0,
      BankInfo:{},
    };
  },
  computed: {
    usdtAmount(){
      return parseFloat(this.amount/this.InitData.USDTUSDT).toFixed(2);
    }
  },
  watch: {},
  created() {
    this.$Model.GetUserInfo()
    this.$toast.loading();
    this.$Model.GetBankCardList(data=>{
      if (data.code==1&&data['data'].length>0){
            this.BankInfo = data['data'][0];
          }
        }
    );
  },
  mounted() {
    // this.getQRUrl();
  },
  activated() {},
  destroyed() {
  },
  methods: {
    onClickRight(){
      this.$router.push("/user/withdrawlist");
    },
    setAmount(){
      this.amount = this.UserInfo.balance2
      // this.BankInfo.money = 333
    },
    submitDraw() {
      let postData = this.BankInfo;
      this.$Model.Draw(postData, (data) => {
        // this.isSubmit = false;
        let msg = this.$t('taskOrder2[3]');
        switch (data.code) {
          case 1:
            msg = this.$t('common3[0]');
            break;
          case -1:
            msg = this.$t('withdraw2[6]');
            break;
          default:
            msg = data.code_dec;
            break;
        }
        this.$Dialog.Toast(msg);
        if (data.code==1){
          this.$router.push("/user");
        }
      });
    },
  },
};
</script>

<style scoped>
.PageBox >>> .van-nav-bar__title{
  font-size: 18px;
}
.PageBox >>> .van-nav-bar .van-nav-bar__arrow {
  font-size: 20px;
}

.PageBox {
  overflow: auto;
  padding-top: 60px;
  background: url(~@/assets/img/new/login_top.png) no-repeat;
  background-size:  100% auto;
}


.titlePanel {
  margin: 10px;
  border-radius: 10px;
  line-height: 30px;
  padding: 20px;
  font-size: 17px;
  background-color: var(--panel_color);
}

.box .van-cell{
  padding-left: 15px;

}

.van-cell >>> .van-field__control {
  color: var(--cell_title) !important;
  padding-left: 7px;
  height: 100%;
}

.van-cell >>> .van-field__control::-webkit-input-placeholder {
  color: var(--cell_placeholder) !important;
  font-size: 19px;
  font-family: Alibaba;
}

.van-cell >>> .van-field__control::-moz-placeholder {
  color: var(--cell_placeholder) !important;
  font-family: Alibaba;
}

.PageBox .van-cell>>>.van-cell__title{
  font-size: 15px;
  font-weight: bold;
  color: var(--cell_title) ;
}
.PageBox .box {
  padding: 10px 0px 10px 0px;
  font-size: 16px;
  border-radius: 10px;
  margin: 10px;
background-color: var(--panel_color);
  color: #555;
}


.van-cell {
  background-color: transparent;
}

p{
  width: 80px;
}


.btn {
  width: 85%;
  padding: 10px 50px;
  border-radius: 5px;
  background-color: var(--button_bg);
  color: #FFFEFF;
  font-size: 18px;
  text-align: center;
  margin: 15px 30px 30px;
}
</style>
